'use client'
import { type FC } from 'react'
import { useRouter } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useSession, useTranslator, useToast, useModal } from '@/hooks'
import { autoComplete, inputPattern, cookie } from '@/utils'
import { Button, Input } from '..'
import { MODALS } from '@/context'

type TypeFormInputs = {
  email: string,
  password: string
}

export const FormAuthLogin: FC = () => {
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm<TypeFormInputs>()
  const { push } = useRouter()
  const { t } = useTranslator()
  const { setUser } = useSession()
  const { toggle:toggleForgotPassword } = useModal(MODALS.FORGOT_PASSWORD)
  const { addToast } = useToast()

  const onSubmit:SubmitHandler<TypeFormInputs> = async (formData) => {
    try {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      if (!res.ok) throw new Error('FormAuthLogin submit failed')
      if(res.status === 200) {
        addToast({
          message: 'Iniciando sesión',
          type: 'loading'
        })

        const data = await res.json()

        cookie.set('DY_VIRBAC_XML_USER', JSON.stringify(data), { maxAge: getMaxAge(data.exp) })
        setUser({ ...data.user, token: data.token })
        const href = '/'
        push(href)
      }
    } catch(e) {
      console.warn('Login submit - Catch e', e)
      addToast({
        message: 'No se ha podido iniciar sesión, contacte con soporte',
        type: 'error'
      })
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required={{
          required: t('form.errors.pattern_email') as string,
          pattern: inputPattern.email
        }} error={formErrors.email} bgColor={'background_login'} />
        <Input type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.current} icon={'password'} bgColor={'background_login'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
        <Button type='submit'>Log in</Button>
        <ButtonReset type='button' onClick={toggleForgotPassword}>He olvidado mi contraseña</ButtonReset>
      </Form>
    </>
  )
}

const Form = styled.form`
  button {
    &[type='submit'] {
      margin: 40px auto 20px;
    }
  }
`

const ButtonReset = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blue};
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 25px;
  margin: 0 auto;
  text-decoration: none;
`

export const getMaxAge = (dateString: string): number => {
  // Parse the date string into a Date object
  const date = new Date(dateString)

  // Calculate the time difference between the parsed date and the current date
  const currentTime = new Date()
  const timeDifferenceMilliseconds = date.getTime() - currentTime.getTime()

  // Convert the time difference to seconds for maxAge
  const maxAgeSeconds = Math.floor(timeDifferenceMilliseconds / 1000)
  return maxAgeSeconds
}
