'use client'
import { type FC } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslator, useToast } from '@/hooks'
import { autoComplete, inputPattern } from '@/utils'
import { Button, Input } from '..'

type Props = {
}

type TypeFormInputs = {
  password: string
}

export const FormAuthSignUp: FC<Props> = ({ }) => {
  const { register, handleSubmit } = useForm<TypeFormInputs>()
  const { push } = useRouter()
  const { t } = useTranslator()
  const { addToast } = useToast()
  const searchParams = useSearchParams()


  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData) => {
    const token = searchParams.get('token')
    const body = {
      password: formData.password,
      token
    }

    console.log('bodyyy', body)

    try {
      const res = await fetch('/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })

      if (!res.ok) throw new Error('FormAuthLogin submit failed')
      if (res.status === 200) {
        addToast({
          message: 'Redirigiendo a la página de inicio de sesión',
          type: 'loading'
        })

        push('/login')
      }
    } catch (e) {
      console.warn('Login submit - Catch e', e)
      addToast({
        message: 'No se ha podido completar el registro, contacte con soporte',
        type: 'error'
      })
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.new} icon={'password'} bgColor={'background_login'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
        <Button type='submit'>Siguiente</Button>
      </Form>
    </>
  )
}

const Form = styled.form`
  label {
    padding-left: 0;
  }

  button {
    &[type='submit'] {
      margin: 40px 0 20px;
    }
  }
`
