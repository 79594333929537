'use client'
import { type FC } from 'react'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'
import { FormAuthLogin } from '../forms/FormAuthLogin'
import { FormAuthSignUp } from '../forms/FormAuthSignUp'
import { FormAuthResetPassword } from '../forms/FormAuthResetPassword'

type Props = {}

const formAuthByType = {
  'login': () => <FormAuthLogin />,
  'signup': () => <FormAuthSignUp />,
  'reset-password': () => <FormAuthResetPassword />
}

export const SectionAuth:FC<Props> = () => {
  const pathname = usePathname()

  return <Section>
    {formAuthByType[pathname.split('/')?.[1]]()}
  </Section>
}

const Section = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
`
